import React from "react"
import Layout from "../components/layout"
import "../styles/index-web.scss"
import "../styles/index-mobile.scss"
import blueMarker from "../images/heading marker light blue.svg"
import purpleMarker from "../images/heading marker purple.svg"
import greenMarker from "../images/heading marker green.svg"
import darkBlueMarker from "../images/heading marker dark blue.svg"
import lightBlueMarker from "../images/heading marker light blue.svg"
import grapicTwo from "../images/graphic 2.svg"
import grapicOne from "../images/graphic 1.svg"
import grapicThree from "../images/graphic 3.svg"
import grapicFour from "../images/graphic 4.svg"
import grapicFive from "../images/graphic 5.svg"
import purpleDots from "../images/Export Assets_Dots purple.svg"
import quadrantDarkBlue from "../images/Quadrant dark blue.svg"
import quadrantMidBlue from "../images/Quadrant mid blue.svg"
import squareLightBlue from "../images/Square light blue.svg"
import triangleGreen from "../images/Triangle Green.svg"
import trianglePurple from "../images/Triangle purple.svg"
import { Link } from "gatsby"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
      <SEO title="Scryer Software House" keywords={[`scryer`, `software`, `developers`, `development`, `jobs` , `career`]} />
    <div id="vision" className="hashRoute"></div>
    <div className="section vision">
      <div className="heading-bar">
        <hr className="inline" />
        <img src={blueMarker} alt="" className="marker" />
        <span className="heading">
          Vision
        </span>
        <span className="spacer"></span>
      </div>
      <img src={purpleDots} alt="" className="dots" />
      <div className="text">
        <p><b>
          We make apps that need to be made.
        </b></p>
        <p>
          Based in Norwich, England, Scryer is a group of developers passionate about programming.
        </p>
        <p>
          Having built and implemented B2B software for some of the largest organisations in the world,
          2022 is the year we're setting our sights on consumer markets.
        </p>
      </div>
      <img src={grapicTwo} alt="" className="graphic" />
    </div>
    <div id="people" className="hashRoute"></div>
    <div className="section people">
      <img src={triangleGreen} alt="" className="shape" />
      <div className="heading-bar">
        <span className="spacer"></span>
        <img src={purpleMarker} alt="" className="marker" />
        <span className="heading">
          People
        </span>
        <hr className="inline" />
      </div>
      <img src={grapicOne} alt="" className="graphic" />
      <div className="text">
        <p><b>
          This is a company of programmers.
          </b></p>
        <p>
          No egos, no dramas, just people that love coding (and coffee).
          </p>
        <p>
          We work Full Stack, Kanban, with all the freedoms and responsibilities that go with it. It works for us.
          If you're interested in learning more about developer positions, let us know,
          </p>
      </div>
    </div>
    <div id="ambition" className="hashRoute"></div>
    <div className="section ambition">
      <img src={quadrantMidBlue} alt="" className="shape" />
      <div className="heading-bar">
        <hr className="inline" />
        <img src={greenMarker} alt="" className="marker" />
        <span className="heading">
          Ambition
        </span>
        <span className="spacer"></span>
      </div>
      <div className="text">
        <p><b>
          We're looking to change the world, quietly.
          </b></p>
        <p>
          Good skills are hard to find, and a team of people with them is a rare thing.
        </p>
        <p>
          We want to grow our skills and put them to good use.
          Whether that's through impactful Fintech, Environmental or Consumer Apps. It's ok to want to change things.
        </p>
      </div>
      <img src={grapicThree} alt="" className="graphic" />
    </div>
    <div id="community" className="hashRoute"></div>
    <div className="section community">
      <img src={squareLightBlue} alt="" className="shape" />
      <div className="heading-bar">
        <span className="spacer"></span>
        <img src={darkBlueMarker} alt="" className="marker" />
        <span className="heading">
          Community
        </span>
        <hr className="inline" />
      </div>
      <img src={grapicFour} alt="" className="graphic" />
      <div className="text">
        <p><b>
          We're always looking for community collaborations.
        </b></p>
        <p>
          Though the pandemic has made in-person events difficult, we're looking to kick start meetups as Norwich opens up again, with a focus on workshops for aspiring coders.
        </p>
        <p>
          If you're interested in getting involved in a voluntary capacity please contact us at contact@scryer.dev.
        </p>
      </div>
    </div>
    <div id="careers" className="hashRoute"></div>
    <div className="section careers">
      <img src={trianglePurple} alt="" className="shape" />
      <div className="heading-bar">
        <hr className="inline" />
        <img src={lightBlueMarker} alt="" className="marker" />
        <span className="heading">
          Careers
        </span>
        <span className="spacer"></span>
      </div>
      <div className="text">
        <p><b>
          You might be what we've been looking for.
        </b></p>
        <p>
        <Link to="/positions/software-developer"> <b>Click here</b></Link> to learn more about our open positions.
        </p>
        <p>
          If there isn't a role that fits you right now, you can also
          join our mailing list and be contacted as future postions become available.
        </p>
        <p>
          We look forward to hearing from you!
        </p>
      </div>
      <img src={grapicFive} alt="" className="graphic" />
    </div>
    <div className="section contact">
      <img src={quadrantDarkBlue} alt="" className="shape" />

      <div className="text">
        <p><b>Scryer Limited</b></p>
        <p><b>contact@scryer.dev</b></p>
        <p>15 Upper King Street</p>
        <p>Norwich</p>
        <p>NR3 1RB</p>
        <br />
      </div>

    </div>
  </Layout>
)

export default IndexPage